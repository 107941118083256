import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import Card from '@ui-kit/components/Card/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import navigationRoutes from 'constants/navigationRoutes';
import Link from 'next/link';
import useFormattedLocalDateTime from '@ui-kit/hooks/useFormattedLocalDateTime';
import isEmpty from 'lodash/isEmpty';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fetchList as fetchListAPI } from '@api/listAPI';
import { getUserByLogin as getUserByLoginAPI } from '@api/userAPI';
import { getCookie } from 'cookies-next';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';
import itemImg from '@ui-kit/components/Card/item/item.png';

function ForeignItemCard({ item }) {
  const { t } = useTranslation();
  const token = getCookie(USER_TOKEN_COOKIE_NAME);
  const [list, setList] = useState(null);
  const [authorName, setAuthorName] = useState('');

  useEffect(() => {
    fetchListAPI({ userToken: token, listId: item?.listId }).then(response => {
      setList(response?.data ?? {});
    });
  }, [token, item?.listId]);

  useEffect(() => {
    if (!isEmpty(list?.userLogin)) {
      getUserByLoginAPI(list?.userLogin).then(response => {
        setAuthorName(response?.data?.displayName ?? list?.userLogin ?? '');
      });
    }
  }, [list?.userLogin]);

  const caption = React.useMemo(() => {
    return isEmpty(list)
      ? null
      : (
        <div className="flex flex-col gap-0.5 items-left">
          <div className="flex gap-x-1">
            <Typography variant="subtitle1" color="text.secondary" component="div" className="clamp-text-1 text-left">
              {t('fromList')}
            </Typography>
            <Typography variant="subtitle1" component="div" className="clamp-text-1 text-left text-primary-main">
              {list?.title ?? ''}
            </Typography>
          </div>
          <Typography variant="caption" color="text.secondary" className="clamp-text-1 text-left">
            {t('fromAuthor')} {authorName}
          </Typography>
        </div>
      );
  }, [t, list, authorName]);

  const date = useFormattedLocalDateTime(list?.eventDate);

  const actions = React.useMemo(() => {
    return [
      <div key="date" className="flex gap-1 items-center text-secondary h-6">
        {!isEmpty(date) && <AccessTimeIcon fontSize="inherit" />}
        <Typography variant="caption">
          {date}
        </Typography>
      </div>,
      isEmpty(token)
        ? null
        : <Button key="addToMyFav" className="ml-auto hidden">{t('addToMyFav')}</Button>,
    ];
  }, [date, token, t]);

  return (
    <Link
      shallow={true}
      scroll={false}
      prefetch={false}
      href={`${navigationRoutes.INTERESTS_PAGE.path}/${item.listId}#${item.itemId}`}
      className="card-shadow rounded-[20px]"
    >
      <Card
        title={item?.name}
        imageUrl={item?.image}
        caption={caption}
        actions={actions}
        defaultImgUrl={itemImg.src}
      />
    </Link>
  );
}

export default React.memo(ForeignItemCard, isEqual);
