import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Typography } from '@mui/material';
import navigationRoutes from 'constants/navigationRoutes';
import MyGiftItems from 'components/pages/main/gifts/MyGiftItems';
import { fetchMyGifts as fetchMyGiftsAPI } from '@api/itemAPI';
import Link from 'next/link';
import ItemsStub from '../ItemsStub';

function MyGiftsAuthorised() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [myGifts, setMyGifts] = useState();

  useEffect(() => {
    setLoading(true);
    fetchMyGiftsAPI({ limit: 6 }).then(({ data }) => {
      setMyGifts(data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="flex justify-start items-center">
        <Typography variant="h1" className="pr-4">{t('mainPage:myGiftsMainPage')}</Typography>
        <Link shallow={true} prefetch={false} href={navigationRoutes.GIFTS_PAGE.path}>
          <Typography
            variant="body1"
            color="inherit"
            className="underline text-secondary hover:text-primary-main"
          >
            {t('mainPage:linkToAllMainPage')} {'>'}
          </Typography>
        </Link>
      </div>
      {loading
        ? <ItemsStub />
        : <MyGiftItems myGifts={myGifts} />
      }
    </div>
  );
}

export default MyGiftsAuthorised;
