import React from 'react';
import { isEmpty } from 'lodash';
import MyGiftsAuthorised from 'components/pages/main/gifts/MyGiftsAuthorised';

function MyGifts({ userToken }) {
  return isEmpty(userToken)
      ? null
      : <MyGiftsAuthorised />;

}

export default MyGifts;
