import axios from '@api/axiosInstance';
import { formatDateForSaving } from '@utils/dateTimeUtils';
import isEmpty from 'lodash/isEmpty';
import { getCookie } from 'cookies-next';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';

export function fetchListItem({ userToken, itemId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(`/api/items/byId?itemId=${itemId ?? ''}&token=${token}`);
}

export function fetchListItems({ userToken, listId, limit, offset }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(
    `/api/items?listId=${listId ?? ''}&limit=${limit ?? ''}&offset=${offset ?? ''}&token=${token}`,
  );
}

export function fetchMyGifts({ userToken, limit, offset }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(`/api/items/myGifts?limit=${limit ?? ''}&offset=${offset ?? ''}&token=${token}`);
}

export function postItem({ userToken, item, listId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.post(`/api/items?listId=${listId ?? ''}&token=${token}`, {
    itemId: item?.itemId ?? null,
    name: item?.name ?? '',
    description: item?.description ?? '',
    image: item?.image ?? '',
    currency: item?.currency ?? '',
    importance: item?.importance ?? '',
    link: item?.link ?? '',
    price: item?.price ?? null,
    quantity: item?.quantity ?? 1,
    presented: item?.presented ?? false,
    bookedBy: item?.bookedBy ?? '',
    createdAt: formatDateForSaving(item?.createdAt),
  });
}

export function moveItems({ userToken, listId, itemIds }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.put(`/api/items/move?listId=${listId ?? ''}&token=${token}`, itemIds);
}

export function toggleItemBooking({ userToken, itemId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.put(`/api/items/book?itemId=${itemId ?? ''}&token=${token}`);
}

export function deleteItem({ userToken, itemId }) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.delete(`/api/items?itemId=${itemId}&token=${token}`);
}
