import React from 'react';
import ForeignItemCard from '@ui-kit/components/Card/item/ForeignItemCard';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

function MyGiftItems({ myGifts }) {
  const { t } = useTranslation();

  return isEmpty(myGifts)
    ? (
      <Typography variant="body1" className="pr-4 text-left" color="text.secondary">
        {t('mainPage:adviceToBookFriendsList')}
      </Typography>
    )
    : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {map(myGifts, (myGift, idx) => (
          <ForeignItemCard
            key={`${idx}_${myGift?.name}`}
            item={myGift}
          />
        ))}
      </div>
    );
}

export default MyGiftItems;
